import type { QueryFilterCollection, QuerySort, QuerySortLoose } from "~/helpers/apollo/types";

export const getFilterColor = (active: boolean) => `--color-${active ? "purple" : "black"}-900`;

export type FilterBookmark = {
  id: string;
  name: string;
  notifyUserOnChangesToOutput: boolean;
  filters: QueryFilterCollection;
  isDefault: boolean;
  columns?: string[] | null;
  sort?: QuerySortLoose | null;
};
